import React from "react";
import classes from "./Home.module.css";
import Header from "../common/Header/Header";
import Flags from "country-flag-icons/react/3x2";
import Footer from "../common/Footer/Footer";

function Home() {
  return (
    <div className="pageWrapper-m">
      <Header />
      <div className="static-background"></div>
      <div className="sect-m">
        <div className="sect-title-m">
          Over 10 years of expertise in{" "}
          <span className={classes.import}>Import</span>{" "}
          <span className={classes.and}>and</span>{" "}
          <span className={classes.export}>Export</span>
        </div>
        <div className="sect-content-m">
          We are{" "}
          <span className="sect-content-highlighted-m">
            Suxxus Global Trading Chennai Pvt. Ltd.
          </span>
        </div>
      </div>
      <div className="sect-m">
        <div className="sect-title-medium-m">A GLIMPSE ABOUT US</div>
        <div className="sect-content-m">
          <div className="content-grid-container-m">
            <div className="content-grid-container-column-m">
              <div className="content-grid-item content-grid-item-1">
                Established in 2013, we are an indenting and trading company. We
                use our widely experienced management in the field of
                international imports and exports to cater the needs of the
                Indian industry.
              </div>
              <div className="content-grid-item content-grid-item-4">
                We are based in Chennai and supply over 20000 mt tonnes of waste
                paper, annually, to the Indian paper industry.
              </div>
              <div className="content-grid-item content-grid-item-7">
                We are an established supplier of silicon to India and nearby
                countries.
              </div>
            </div>
            <div className="content-grid-container-column-m">
              <div className="content-grid-item content-grid-item-6">
                We supply silicon from major silicon manufacturing countries
                like China, Turkey, Malaysia, Latin America to aluminium
                foundries for the manufacture of various grades of aluminium
                ingots essential for automobiles and various other industries.
              </div>
              <div className="content-grid-item content-grid-item-3">
                We are helmed by a team having good experience in dealing with
                raw material suppliers in the field of waste paper, non ferrous
                scraps, silicon metal and various other industrial supplies.
              </div>
            </div>
            <div className="content-grid-container-column-m">
              <div className="content-grid-item content-grid-item-2">
                Our endeavour is to act as a genuine intermediary to deliver
                quality and add value to all our transactions
              </div>
              <div className="content-grid-item content-grid-item-5">
                Our product portfolio includes most major and minor grades as
                well as many country specific grades of Paper. We also supply
                5000mt silicon metal of various grades annually to Indian
                aluminium industry. We also deal in aluminium scraps like trump,
                tense, taint tabor, troma, twitch, talc, talons etc and have
                been selling to some of the major aluminium foundries in India.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sect-m">
        <div className="sect-title-medium-m">SUPPLY STATS</div>
        <div className="stat-container-m">
          <div className="stat-item-m">
            <div className="stat-item-num-m">
              5000+<div className="stat-unit-m">tonnes/year</div>
            </div>
            <div className="stat-item-desc-m">Silicon metal supplied</div>
          </div>
          <div className="stat-separator"></div>
          <div className="stat-item-m">
            <div className="stat-item-num-m">
              20000+<div className="stat-unit-m">tonnes/year</div>
            </div>
            <div className="stat-item-desc-m">Paper supplied</div>
          </div>
        </div>
      </div>

      <div className="sect-m">
        <div className="sect-title-medium-m">OUR SUPPLIER NETWORK</div>
        <div className="sect-title-submedium-m">Silicon</div>
        <div className="flag-list-m">
          <div className="flag-list-item-m">
            <Flags.CN className="flagimg"></Flags.CN>
            <div className="flag-list-item-m-text">China</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.TR className="flagimg"></Flags.TR>
            <div className="flag-list-item-m-text">Turkey</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.MY className="flagimg"></Flags.MY>
            <div className="flag-list-item-m-text">Malaysia</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.BR className="flagimg"></Flags.BR>
            <div className="flag-list-item-m-text">Brazil</div>
          </div>
        </div>
        <div className="sect-title-submedium-m">Paper</div>
        <div className="flag-list-m">
          <div className="flag-list-item-m">
            <Flags.US className="flagimg"></Flags.US>
            <div className="flag-list-item-m-text">USA</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.CA className="flagimg"></Flags.CA>
            <div className="flag-list-item-m-text">Canada</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.IT className="flagimg"></Flags.IT>
            <div className="flag-list-item-m-text">Italy</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.GB className="flagimg"></Flags.GB>
            <div className="flag-list-item-m-text">UK</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.MT className="flagimg"></Flags.MT>
            <div className="flag-list-item-m-text">Malta</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.ES className="flagimg"></Flags.ES>
            <div className="flag-list-item-m-text">Spain</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.KW className="flagimg"></Flags.KW>
            <div className="flag-list-item-m-text">Kuwait</div>
          </div>
          <div className="flag-list-item-m">
            <Flags.ZA className="flagimg"></Flags.ZA>
            <div className="flag-list-item-m-text">South Africa</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
