import React from "react";
import classes from "./Header.module.css";
import { NavLink } from "react-router-dom";

function Header() {
  return (
    <div className={classes.header}>
      <div className={classes.headerLogo}>SUXXUS GLOBAL</div>
      <div className={classes.headerNav}>
        <NavLink
          to="/home"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          About
        </NavLink>
        <NavLink
          to="/products"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Products
        </NavLink>
        <NavLink
          to="/contact"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Contact
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
