import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Products from "./Products/Products";
import Contact from "./Contact/Contact";
import PageNotFound from "./PageNotFound/PageNotFound";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" exact element={<Navigate replace to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} exact />
        <Route path="/products" element={<Products />} exact />
        <Route path="/contact" element={<Contact />} exact />
        <Route path="/tnc" element={<TermsAndConditions />} exact />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
