import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Footer.module.css";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className={classes.footer}>
      <div className={classes.headerLogo}>SUXXUS GLOBAL</div>
      <div className={classes.footerContainer}>
        <div className={classes.tagline}>AIMING HIGH</div>
        <div className={classes.footerNavContainer}>
          <div className={classes.footerNavTitle}>Explore</div>
          <div className={classes.footerNavLinks}>
            <NavLink to="/home">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/products">Products</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/tnc">Terms and Conditions</NavLink>
          </div>
        </div>
        <div className={classes.footerNavContainer}>
          <div className={classes.footerNavTitle}>Visit us</div>
          <div className={classes.footerNavLinks}>
            No 22, Murugan Complex
            <br />
            49/82, Brindhavan Street,
            <br />
            West Mambalam,
            <br />
            Chennai - 600 033
          </div>
        </div>
        <div className={classes.footerNavContainer}>
          <div className={classes.footerNavTitle}>Call us</div>
          <div className={classes.footerNavLinks}>
            +91 9841 070 174
            <br />
            +91 9841 023 133
            <br />
            +91 44 4218 5904
          </div>
        </div>
      </div>
      <div className={classes.footerSeparator}></div>
      <div className={classes.footerLineContainer}>
        <div>
          &#169; {year} Suxxus Global Trading Chennai Pvt. Ltd. All rights
          reserved.
        </div>
        <div>Terms and Conditions apply.</div>
      </div>
    </div>
  );
};

export default Footer;
