import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import DApp from "./d/App";
import MApp from "./m/App";
import { BrowserRouter } from "react-router-dom";

console.log(navigator.userAgent);

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  ReactDOM.render(
    <BrowserRouter>
      <MApp />
    </BrowserRouter>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <DApp />
    </BrowserRouter>,
    document.getElementById("root")
  );
}
