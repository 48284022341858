import React from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";

const TermsAndConditions = () => {
  return (
    <div className="page-wrapper">
      <Header />
      <div className="static-background"></div>
      <div className="sect">
        <div className="sect-title">TERMS & CONDITIONS</div>
        <div className="sect-title-submedium">
          GENERAL INTERNATIONAL TERMS AND CONDITIONS
        </div>
        <div className="sect-content left-align">Quality:</div>
        <div className="sect-content left-align">
          <ul>
            <li className="terms-li-d">
              If the buyer finds any deviation in the quality as per the order,
              inform us immediately during visual inspection of the material at
              the Customs. We will talk to our supplier and act according to the
              supplier's request.
            </li>
            <li className="terms-li-d">
              If buyer observes that there will be quality problems in the
              material, after discussions with Supplier, Buyer should either
              unload the cntr at his yard/factory and segregate the material and
              send us the Report, In case the quality problem is critical, we
              can be present during segregation of the material. If the supplier
              wants to go in for a third party inspection, the buyer has to
              organize a third party inspection at their cost and has to send us
              the report. Based on the report, supplier, Indentor and Buyer will
              amicably settle the issue.
            </li>
          </ul>
        </div>
        <div className="sect-content left-align">Weight:</div>
        <div className="sect-content left-align">
          <ul>
            <li className="terms-li-d">
              After Custom clearance of the container , weigh it in Custom
              weighbridge if possible.
            </li>
            <li className="terms-li-d">
              Weigh the material if found short at 2-3 weigh bridges during the
              transportation of the material to the destination.
            </li>
            <li className="terms-li-d">
              If the shortage is more than the 1% tolerance allowed or as agreed
              as per contract please call us immediately and do not open/tamper
              the container seal. Please wait for further instructions from our
              side.
            </li>
            <li className="terms-li-d">
              The Indentor will discuss the shortage with the Supplier and
              either personally inspect the container or the Buyer has to
              appoint a Third party inspection agency to check the weight at
              their cost. The cost finally will be borne by the loser or be
              amicably settled.
            </li>
          </ul>
        </div>
        <div className="sect-content left-align">Remarks:</div>
        <div className="sect-content left-align">
          <ul>
            <li className="terms-li-d">
              In the case of quality complaints and claims please note the issue
              cannot be solved within a couple of hours or days. As per past
              experience it normally takes more than 7-21 working days to settle
              the problem. This time duration may exceed depending on the
              gravity of the matter and the proceeds of the claim.
            </li>
            <li className="terms-li-d">
              Please note that Supplier is interested to settle any claims
              arising out of his material, as he wants to have a reputed name in
              the market and wants to continue the business with existing
              buyers.
            </li>
            <li className="terms-li-d">
              As Indentors, we are always there to see that the buyer's genuine
              claims are settled amicably and the buyer is satisfied with the
              efforts of Indentor. If we do not give service to buyers, they are
              not going to revert back with any further orders.
            </li>
            <li className="terms-li-d">
              In case of claim, Supplier and Buyer have to amicably arrive at a
              settlement and foresee the future business. During this process,
              Supplier and Buyer may have a small financial burden, but we
              guarantee that we will reimburse/give concessions in subsequent
              orders and compensate for the loss if any.
            </li>
            <li className="terms-li-d">
              In case of scraps we may sometimes have quality complaints in the
              material. Supplier is not responsible for any claim, if the
              container is already unloaded.
            </li>
            <li className="terms-li-d">
              For Semi finished or finished products with clear markings the
              material may be unloaded and kept separately in the buyers yard/
              factory till further instructions from the Supplier through the
              indentors.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
