import React from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import classes from "./Products.module.css";
import Carousel from "react-elastic-carousel";
import "./arrowstyle.css";

function Products() {
  return (
    <div className="pageWrapper-m">
      <Header />
      <div className="static-background"></div>
      <div className="sect-m">
        <div className="sect-title-m">PRODUCTS</div>
        <div className="sect-title-medium-m">Waste Paper</div>
        <Carousel
          className={classes.carousel_m}
          itemsToShow={1}
          itemsToScroll={1}
          enableAutoPlay={false}
          autoPlaySpeed={4000}
        >
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL +
                "assets/images/wastepaper/whitecuttings.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>White Cuttings</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/SWL.jpg"}
            />
            <div className={classes.carousel_mesc_m}>SWL</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/SOP2.jpg"}
            />
            <div className={classes.carousel_mesc_m}>SOP - 2</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL +
                "assets/images/wastepaper/ScanBoard.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>Scan Board</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/POC.jpg"}
            />
            <div className={classes.carousel_mesc_m}>POC</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/OCC.jpg"}
            />
            <div className={classes.carousel_mesc_m}>OCC</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL + "assets/images/wastepaper/OCC955.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>OCC 95/5</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL +
                "assets/images/wastepaper/kraftbags.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>Kraft Bags</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/HWS.jpg"}
            />
            <div className={classes.carousel_mesc_m}>HWS</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/EOCC.jpg"}
            />
            <div className={classes.carousel_mesc_m}>EOCC</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL +
                "assets/images/wastepaper/DSOCCpremium.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>DSOCC Premium</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL + "assets/images/wastepaper/DSOCC.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>DSOCC</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL + "assets/images/wastepaper/cupstock.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>Cupstock</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/BHWP.jpg"}
            />
            <div className={classes.carousel_mesc_m}>BHWP</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/BBC.jpg"}
            />
            <div className={classes.carousel_mesc_m}>BBC</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/wastepaper/HWEC.jpg"}
            />
            <div className={classes.carousel_mesc_m}>HWEC</div>
          </div>
        </Carousel>
        <div className="sect-content-m">From North America and Canada</div>
        <div className={classes.products_container_m}>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 1</div>
            <div className={classes.products_item_content_m}>
              RESIDENTIAL PAPER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 2</div>
            <div className={classes.products_item_content_m}>
              SOFT MIX PAPER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 3</div>
            <div className={classes.products_item_content_m}>
              HARD MIX PAPER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 4</div>
            <div className={classes.products_item_content_m}>BBC</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 5</div>
            <div className={classes.products_item_content_m}>MILL WRAPPERS</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 6</div>
            <div className={classes.products_item_content_m}>NEWS</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 7</div>
            <div className={classes.products_item_content_m}>NEWS</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 8</div>
            <div className={classes.products_item_content_m}>NEWS</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 9</div>
            <div className={classes.products_item_content_m}>
              OVER ISSUE MAGAZINES
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 10</div>
            <div className={classes.products_item_content_m}>
              OVER ISSUE MAGAZINES
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 11</div>
            <div className={classes.products_item_content_m}>OCC</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 12</div>
            <div className={classes.products_item_content_m}>DSOCC</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 13</div>
            <div className={classes.products_item_content_m}>NDLKC</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 14</div>
            <div className={classes.products_item_content_m}>FIBER CORES</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 15</div>
            <div className={classes.products_item_content_m}>
              USED BROWN KRAFT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 16</div>
            <div className={classes.products_item_content_m}>
              MIXED KRAFT CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 17</div>
            <div className={classes.products_item_content_m}>CARRIER KRAFT</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 20</div>
            <div className={classes.products_item_content_m}>
              KRAFT MULTI WALL BAG SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 122</div>
            <div className={classes.products_item_content_m}>
              MIXED GROUND WOOD SHAVINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 23</div>
            <div className={classes.products_item_content_m}>
              TELEPHONE DIRECTORIES
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 25</div>
            <div className={classes.products_item_content_m}>
              GROUND WOOD COMPUTER PRINT OUT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 27</div>
            <div className={classes.products_item_content_m}>
              FLY LEAF SHAVINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 28</div>
            <div className={classes.products_item_content_m}>
              COATED SOFT WHITE SHAVINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 30</div>
            <div className={classes.products_item_content_m}>
              HARD WHITE SHAVINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 31</div>
            <div className={classes.products_item_content_m}>
              HARD WHITE ENVELOPE CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 33</div>
            <div className={classes.products_item_content_m}>
              NEW COLORED ENVELOPE CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 35</div>
            <div className={classes.products_item_content_m}>
              SEMI BLEACHED CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 36</div>
            <div className={classes.products_item_content_m}>
              UNSORTED OFFICE PAPER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 37</div>
            <div className={classes.products_item_content_m}>
              SORTED OFFICE PAPER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 39</div>
            <div className={classes.products_item_content_m}>
              MANIFOLD COLOR LEDGER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 40</div>
            <div className={classes.products_item_content_m}>
              SORTED WHITE LEDGER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 41</div>
            <div className={classes.products_item_content_m}>
              MANIFOLD WHITE LEDGER
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 42</div>
            <div className={classes.products_item_content_m}>
              COMPUTER PRINT OUT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 43</div>
            <div className={classes.products_item_content_m}>
              COATED BOOK STOCK
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>PS 44</div>
            <div className={classes.products_item_content_m}>
              COATED GROUND WOOD SECTIONS
            </div>
          </div>
        </div>
        <div className="sect-content-m">Speciality Grades</div>
        <div className={classes.products_container_m}>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>1-S</div>
            <div className={classes.products_item_content_m}>
              WHITE WAXED CUP CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>2-S</div>
            <div className={classes.products_item_content_m}>
              PRINTED WAXED CUP CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>3-S</div>
            <div className={classes.products_item_content_m}>
              POLY COATED CUP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>4-S</div>
            <div className={classes.products_item_content_m}>
              PRINTED POLY COATED BLEACHED KRAFT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>5-S</div>
            <div className={classes.products_item_content_m}>
              UNPRINTED POLY COATED BLEACHED KRAFT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>6-S</div>
            <div className={classes.products_item_content_m}>
              POLY COATED MILK CARTON STOCK
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>8-S</div>
            <div className={classes.products_item_content_m}>
              POLY COATED BOARD CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>9-S</div>
            <div className={classes.products_item_content_m}>
              WAXED BOX BOARD CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>10-S</div>
            <div className={classes.products_item_content_m}>
              PRINTED OR UNPRINTED BLEACHED SULPHATE WITH FOIL
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>11-S</div>
            <div className={classes.products_item_content_m}>
              WAXED CORRUGATED CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>12-S</div>
            <div className={classes.products_item_content_m}>
              WET STRENGTH CORRUGATED CUTTINGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>14-S</div>
            <div className={classes.products_item_content_m}>
              BEER CARTON SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>15-S</div>
            <div className={classes.products_item_content_m}>
              CONTAMINATED BAG SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>17-S</div>
            <div className={classes.products_item_content_m}>
              WHITE WET STRENGTH SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>18-S</div>
            <div className={classes.products_item_content_m}>
              BROWN WET STRENGTH SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>19-S</div>
            <div className={classes.products_item_content_m}>
              PRINTED AND COLORED WET STRENGTH SCRAP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>20-S</div>
            <div className={classes.products_item_content_m}>FILE STOCK</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>22-S</div>
            <div className={classes.products_item_content_m}>RULED WHITE</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>23-S</div>
            <div className={classes.products_item_content_m}>
              FLYLEAF SHAVINGS WITH HOT MELT GLUE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>24-S</div>
            <div className={classes.products_item_content_m}>CARBON MIX</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>25-S</div>
            <div className={classes.products_item_content_m}>
              BOOKS WITH COVERS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>30-S</div>
            <div className={classes.products_item_content_m}>
              PLASTIC WINDOWED ENVELOPES
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>35-S</div>
            <div className={classes.products_item_content_m}>
              SORTED COLOR LEDGER
            </div>
          </div>
        </div>
        <div className="sect-content-m">From Continental Europe and UK</div>
        <div className={classes.products_container_m}>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>MIXED PAPER</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              EUROPEAN OLD CORRUGATES CARTONS (EOCC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>FRUIT BOXES</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>TOBACCO BOXES</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              NEW CORRUGATED CUTTINGS (NCC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              KRAFT CARRIER BOARD (KCB)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              MULTIWALL PAPER BAGS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>NEWS & PAMS</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OVER ISSUE NEWS PAPER (OINP)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OLD MAGAZINES (OMG)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              MAGAZINE TRIMS
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              COATED BOOK STOCK (CBS)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>MULTI GRADE</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              SORTED OFFICE PACK/SORTED COLOR LETTERS (SOP)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>CUP STOCK</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              UNPRINTED CUP STOCK WITH POLY ON ONE SIDE, ALL SULPHATE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              CUP STOCK POLY COATED ON ONE SIDE WITH PRINTING ON POLY, ALL
              SULPHATE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              CUP STOCK UN PRINTED WITH BOTH SIDE POLY WITH 5% BCTMP
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              PLASTIC WHITE ENVELOPES (PWE)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              HARD WHITE SHAVINGS/ SOFT WHITE SHAVINGS (HWS/SWS)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>SCAN BOARD</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              BOX BOARD CUTTINGS (BBC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              STEEL MILL KRAFT
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              FROM MIDDLE EAST AND AFRICA
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OLD CORRUGATED CARTONS (OCC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              NEW CORRUGATED CARTONS (NCC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              BOX BOARD CUTTINGS (BBC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OVER ISSUE NEWSPAPERS #9 (OINP)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>SCAN BOARD</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              PRINTERS OFF CUT (POC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OVER ISSUE MAGAZINE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              PURE WHITE CUTTINGS (PWC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              SORTED WHITE LEDGER (SWL)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              LIGHT COLOR CUTTINGS (LCC)
            </div>
          </div>
        </div>
        <div className="sect-content-m">From Middle East</div>
        <div className={classes.products_container_m}>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              NEW CORRUGATED CUTTINGS (NCC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              KRAFT CARRIER BOARD (KCB)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OVER ISSUE NEWS PAPER (OINP)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OLD MAGAZINES (OMG)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              COATED BOOK STOCK (CBS)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              SORTED OFFICE PACK/SORTED COLOR LETTERS (SOP)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>CUP STOCK</div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              CUP STOCK POLY COATED ON ONE SIDE WITH PRINTING ON POLY, ALL
              SULPHATE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              CUP STOCK UN PRINTED WITH BOTH SIDE POLY WITH 5% BCTMP
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              HARD WHITE SHAVINGS/ SOFT WHITE SHAVINGS (HWS/SWS)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>SCAN BOARD</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              BOX BOARD CUTTINGS (BBC)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OLD CORRUGATED CARTONS (OCC)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              BOX BOARD CUTTINGS (BBC)
            </div>
          </div>

          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              PRINTERS OFF CUT (POC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              OVER ISSUE MAGAZINE
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              PURE WHITE CUTTINGS (PWC)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_content_m}>
              SORTED WHITE LEDGER (SWL)
            </div>
          </div>
        </div>
      </div>
      <div className="sect-m">
        <div className="sect-title-medium">Silicon and Ferro Silicon</div>
        <div className="sect-content-m">SILICON METAL</div>
        <Carousel
          className={classes.carousel_m}
          itemsToShow={1}
          itemsToScroll={1}
          enableAutoPlay={false}
          autoPlaySpeed={4000}
        >
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/silicon/4411.jpg"}
            />
            <div className={classes.carousel_mesc_m}>441</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/silicon/5531.jpg"}
            />
            <div className={classes.carousel_mesc_m}>553</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/silicon/4412.jpg"}
            />
            <div className={classes.carousel_mesc_m}>441</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/silicon/5532.jpg"}
            />
            <div className={classes.carousel_mesc_m}>553</div>
          </div>
        </Carousel>
        <div className="sect-content-m">
          Silicon, ferrosilicon and other silicon alloys are produced by
          reducing quartz, with coal and iron or other ores at very high
          temperatures (2000°C) in electric arc furnaces. Metallurgical silicon
          metal is used as an alloying agent in the Aluminium industry due to
          its ability to increase the strength of Aluminium. The Silicon makes
          the metal alloy more fluid without breaking the metal down at high
          temperatures. <br />
          We supply the following grades:
        </div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Silicon metal</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>2202</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>3303</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>441</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>553</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              99.5%<span className={classes.tableUnit_m}>min</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              99.5%<span className={classes.tableUnit_m}>min</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              99.0%<span className={classes.tableUnit_m}>min</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              98.5%<span className={classes.tableUnit_m}>min</span>
            </div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Fe</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.20%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.30%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.40%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.50%<span className={classes.tableUnit_m}>max</span>
            </div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Al</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.20%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.30%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.40%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.50%<span className={classes.tableUnit_m}>max</span>
            </div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Ca</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.02%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.03%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.10%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.30%<span className={classes.tableUnit_m}>max</span>
            </div>
          </div>
        </div>
        <div className="sect-content-m">FERRO SILICON</div>
        <div className="sect-content-m">
          An alloy of Iron and Silicon containing 15% to 95% silicon and is used
          for deoxidizing molten steel and making silicon steel and high-silicon
          cast iron. Ferrosilicon is usually smelted in closed submerged arc
          furnaces with a power of 22 to 93 MVA
        </div>
        <div className="sect-content-m">SPECIFICATIONS</div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>Ferro Silicon</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Mn</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>&nbsp;</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>70%-75%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.20%/0.15%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              2%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.03%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.04%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-50mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>
        </div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO
              <br />
              CHROME
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Cr</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>60%-65%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              3.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              8.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.03%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.05%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-100mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>

          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO
              <br />
              CHROME
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Cr</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>55%-60%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              4.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              8.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.04%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.06%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-150mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>
        </div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO CHROME CHIPS
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Cr</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>58%-62%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              4.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              9.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.04%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.05%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              3-10mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>

          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO CHROME FINES
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Cr</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>56%-60%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              4.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              9.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.04%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.06%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0-3mm(90% <span className={classes.tableUnit_m}>min</span>) <br />
              or any other required size.
            </div>
          </div>
        </div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO
              <br />
              MANGANESE
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Mn</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>70%-75%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              1.5%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              8.00%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.30%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.030%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-50mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>

          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              H.C.FERRO
              <br />
              MANGANESE
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Mn</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>75%-80%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              1.5%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              8.00%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.25%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.030%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-150mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>
        </div>
        <div className={classes.tableContainer_m}>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              SILICO
              <br />
              MANGANESE
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Mn</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>60%-65%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              14.0%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              2.5%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.30%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.030%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-50mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>

          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              SILICO
              <br />
              MANGANESE
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Mn</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Si</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>C</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>P</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>S</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>Size</div>
          </div>
          <div className={classes.tableColumn_m}>
            <div className={classes.tableColumnTitle_m}>
              &nbsp;
              <br />
              &nbsp;
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>65%-70%</div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              16.0<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              2.00%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.25%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              0.030%<span className={classes.tableUnit_m}>max</span>
            </div>
            <div className={classes.separator_m}></div>
            <div className={classes.tableContent_m}>
              10-50mm(90% <span className={classes.tableUnit_m}>min</span>){" "}
              <br />
              or any other required size.
            </div>
          </div>
        </div>
        <div className="sect-title-medium">Aluminium Scrap</div>
        <Carousel
          className={classes.carousel_m}
          itemsToShow={1}
          itemsToScroll={1}
          enableAutoPlay={false}
          autoPlaySpeed={4000}
        >
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL + "assets/images/aluminium/altense.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>Al Tense</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={
                process.env.PUBLIC_URL + "assets/images/aluminium/extrusion.jpg"
              }
            />
            <div className={classes.carousel_mesc_m}>Al Extrusion</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/aluminium/fscu.jpg"}
            />
            <div className={classes.carousel_mesc_m}>Al wheels</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/aluminium/img1.jpg"}
            />
            <div className={classes.carousel_mesc_m}>Al Taint Tabor</div>
          </div>
          <div>
            <img
              alt="product_img"
              className={classes.carousel_img_m}
              src={process.env.PUBLIC_URL + "assets/images/aluminium/img2.jpg"}
            />
            <div className={classes.carousel_mesc_m}>Al wheels</div>
          </div>
        </Carousel>
        <div className="sect-content-m">
          Aluminium does not lose quality when it is reused. Metal loss is
          minimal when recycling, and the environmental advantage substantial.
          When aluminium is reused instead of extracting new Aluminium, it saves
          95% of the energy.
          <br />
          Aluminium can be smelted and shaped an infinite number of times. This
          makes aluminium a renewable raw material with an unbeatable
          environmental footprint. <br />
          We deal in all types of aluminium scrap: sheet aluminium, cast
          aluminium, profiles, rims, swarf and offset plates.
          <br />
          Aluminium scrap: as per ISRI specifications
        </div>
        <div className={classes.products_container_m}>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Tablet</div>
            <div className={classes.products_item_content_m}>
              (Lithographic sheets)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Taboo</div>
            <div className={classes.products_item_content_m}>
              (Mixed nw, low Cu/Zn)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Taint Tabor</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Tally</div>
            <div className={classes.products_item_content_m}>(Radiators)</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Talk</div>
            <div className={classes.products_item_content_m}>
              (with or without Iron)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>
              Talon-Tann-Taste
            </div>
            <div className={classes.products_item_content_m}>(wire)</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Telic</div>
            <div className={classes.products_item_content_m}>
              (Borings-turnings)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Tense</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Tutu</div>
            <div className={classes.products_item_content_m}>(Profiles)</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Almg</div>
            <div className={classes.products_item_content_m}>(5xxx)</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>ACSR</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>UBC</div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Tough/Taboo</div>
            <div className={classes.products_item_content_m}>
              (Mixed solids)
            </div>
          </div>
          <div className={classes.products_item_m}>
            <div className={classes.products_item_title_m}>Troma</div>
            <div className={classes.products_item_content_m}>
              (wheels-auto or truck)
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Products;
