import React, { useState } from "react";
import classes from "./Header.module.css";
import { NavLink } from "react-router-dom";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hamburgerHandler = () => {
    setIsMenuOpen((prevState) => !prevState);
  };
  return (
    <div className={classes.header}>
      <div className={classes.headerLogo}>SUXXUS GLOBAL</div>
      <div className={classes.friesContainer} onClick={hamburgerHandler}>
        <div className={classes.fries}></div>
        <div className={classes.fry}></div>
        <div className={classes.fries}></div>
      </div>
      <div
        className={`${classes.headerNav} ${
          isMenuOpen && classes.headerNavOpen
        }`}
      >
        <div className={classes.crossContainer} onClick={hamburgerHandler}>
          <div className={classes.crossOne}></div>
          <div className={classes.crossTwo}></div>
        </div>
        <NavLink
          to="/home"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Home
        </NavLink>
        <NavLink
          to="/about"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          About
        </NavLink>
        <NavLink
          to="/products"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Products
        </NavLink>
        <NavLink
          to="/contact"
          className={(navData) =>
            navData.isActive
              ? classes.active + " " + classes.navLink
              : classes.navLink
          }
        >
          Contact
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
