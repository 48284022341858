import React from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import classes from "./About.module.css";

function About() {
  return (
    <div className="pageWrapper-m">
      <Header />
      <div className="static-background"></div>
      <div className="sect-m">
        <div className="sect-title-m">ABOUT US</div>
        <div className="sect-content-m">
          A tree is strong when the roots are strong. Since inception Suxxus
          Global has grown remarkably steady with loyal clients and
          international buyers and suppliers who have stood with us. Every deal
          made through us is carefully followed up to its logical end with our
          value addition resulting in happy and content buyers and sellers. Our
          strength also flows from our experienced management and our employees.
        </div>
      </div>
      <div className="sect-m">
        <div className="sect-title-m">OUR TEAM</div>
        <div className={classes.teamContainer}>
          <div className={classes.teamMemberContainer}>
            <div className={classes.teamMemberName}>HARI HARA KRISHNAN</div>
            <div className={classes.teamMemberTitle}>Managing Director</div>
            <div className={classes.teamMemberContent}>
              With a scientific and a management degree he has worked in various
              marketing positions in fields like pharmaceuticals, insurance and
              international trading. With the experience at hand and the
              industry he handles the day to day marketing activities in non
              ferrous scrap, waste paper and silicon.
            </div>
          </div>
          <div className={classes.teamMemberContainer}>
            <div className={classes.teamMemberName}>MALATHI</div>
            <div className={classes.teamMemberTitle}>
              Business Development Manager
            </div>
            <div className={classes.teamMemberContent}>
              With a rich experience in workforce management she has joined
              recently and is a valuable addition in general administration and
              handling the day to day affairs of our company.
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
