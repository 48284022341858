import React from "react";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";

function PageNotFound() {
  return (
    <div className="page-wrapper">
      <Header />
      <div className="static-background"></div>
      <div className="sect pageNotFound-d">
        <div className="sect-title">Page Not Found!</div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
