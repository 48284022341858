import React, { useRef, useState } from "react";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import classes from "./Contact.module.css";
import emailjs from "@emailjs/browser";

function Contact() {
  const [formState, setFormState] = useState(0);
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const formRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const nameIsValid = (name) => {
    if (name.length <= 0) {
      return 0;
    }
    return 1;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (nameIsValid(nameRef.current.value) === 0) {
      setNameError("Name Cannot be empty");
      return;
    } else {
      setNameError("");
    }
    if (nameIsValid(emailRef.current.value) === 0) {
      setEmailError("Email cannot be empty");
      return;
    } else {
      setEmailError("");
    }
    setFormState(1);

    emailjs
      .sendForm(
        "service_x4w12zi",
        "template_ya4odwx",
        formRef.current,
        "dV035gSaCMOH6fa9g"
      )
      .then(() => {
        setFormState(2);
      });
  };

  return (
    <div className="pageWrapper-m">
      <Header />
      <div className="static-background"></div>
      <div className="sect-m">
        <div className={classes.formContainer}>
          {formState === 0 && (
            <form className={classes.form} ref={formRef}>
              <div className={classes.formTitle}>Give us a heads up</div>
              <label className={classes.formLabel}>Name</label>
              <input
                type="text"
                name="from_name"
                ref={nameRef}
                className={nameError === "" ? "" : "errorInput"}
              />
              {nameError !== "" && (
                <div className={classes.errorMsg}>{nameError}</div>
              )}
              <label className={classes.formLabel}>Email</label>
              <input
                type="email"
                name="from_email"
                ref={emailRef}
                className={emailError === "" ? "" : "errorInput"}
              />
              {emailError !== "" && (
                <div className={classes.errorMsg}>{emailError}</div>
              )}
              <label className={classes.formLabel}>Message</label>
              <textarea name="message" ref={messageRef} />
              <button
                className={classes.formButton}
                type="submit"
                onClick={submitHandler}
              >
                SUBMIT
              </button>
            </form>
          )}
          {formState === 1 && (
            <div className={classes.formProcessing}>
              <div className={classes.formMessage}>Processing...</div>
              <div className={classes.ldsdualring}></div>
            </div>
          )}
          {formState === 2 && (
            <div className={classes.formSuccess}>
              <div className={classes.formMessage}>
                Thank you for your message. We will get back to you soon.
              </div>
              <div className={classes.thumbsup}>&#128077;</div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
