import React from "react";
import Footer from "../common/Footer/Footer";
import Header from "../common/Header/Header";

function PageNotFound() {
  return (
    <div className="pageWrapper-m">
      <Header />
      <div className="static-background"></div>
      <div className="sect-m pageNotFound-m">
        <div className="sect-title-m">Page Not Found!</div>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
